import chroma from 'chroma-js';
import { palette } from '../theme/palette';
import styled from 'styled-components';
import { MOBILE_MEDIA_QUERY } from 'typography-breakpoint-constants';

const gray = chroma(palette.lightGray)
    .darken(0.5)
    .hex();

export const Card = styled.div`
    box-shadow: 0 0 10px ${palette.lightGray};

    border-radius: 8px;
    border: 1px solid ${palette.lightGray};

    transition: transform 0.3s ease-in-out;

    &:hover {
        box-shadow: 0 0 30px ${gray};
        transform: translateY(-2px);
    }

    a {
        display: block;
        padding: 2rem;
        text-decoration: none;
    }

    ${MOBILE_MEDIA_QUERY} {
        a {
            padding: 1rem;
        }
    }
`;
