import React from 'react';
import { Layout } from '../../components/layout';
import styled from 'styled-components';
import { SocialIcon } from 'react-social-icons';
import { palette } from '../../theme/palette';
import pavan from './pavan.jpg';
import { toHTML } from '../../utils/to-html';
import { Card } from '../../components/card';
import meta from '../../theme/meta';
import { MOBILE_MEDIA_QUERY } from 'typography-breakpoint-constants';

const List = styled.div`
    text-align: center;
    margin-bottom: 4rem;

    & > a {
        margin-right: 2rem;
    }

    & > a:last-child {
        margin-right: 0;
    }
`;

const Avatar = styled.img`
    border-radius: 50%;
    box-shadow: 0 0 15px #777;
`;

const HighlightCard = styled(Card)`
    width: calc(48% - 1rem);
    padding: 0;
    margin: 0.5rem;

    a {
        display: block;
        padding: 1rem;
        text-decoration: none;
    }

    ${MOBILE_MEDIA_QUERY} {
        width: 100%;
    }
`;

const highlights = [
    {
        title: 'MobX Quick Start Guide',
        description: 'Supercharge your React Apps with the MobX State Management techniques',
        url: 'https://www.amazon.com/MobX-Quick-Start-Guide-Supercharge/dp/1789344832',
        icon: require('./mobx.jpg'),
    },
    {
        title: 'The UI Dev',
        description: 'An online school focused on teaching UI Development from the ground up',
        url: 'https://theuidev.com',
        icon: require('./the-ui-dev.png'),
    },
    {
        title: 'QuickLens',
        description: 'Explore the UI, down to the pixels. A Mac app for UI Designers/Developers',
        url: 'https://theuidev.com',
        icon: require('./quicklens.png'),
    },
    {
        title: 'Envato Tuts+',
        description: 'Authored several articles and video courses on Web Development',
        url: 'https://tutsplus.com/authors/pavan-podila',
        icon: require('./tuts_logo.svg'),
    },
    {
        title: 'WPF Control Development Unleashed',
        description: 'Build advanced User Interfaces with WPF',
        url: 'http://www.amazon.com/WPF-Control-Development-Unleashed-Experiences/dp/0672330334',
        icon: require('./wpf.jpg'),
    },
    {
        title: 'FluidKit',
        description: 'A library of WPF Custom Controls',
        url: 'http://fluidkit.codeplex.com/',
        icon: require('./fluidkit.png'),
    },
];

export default class extends React.Component {
    render() {
        const {
            author: { name, bio, twitter, github, linkedIn, email },
        } = meta;
        return (
            <Layout title={'About'}>
                <div style={{ textAlign: 'center' }}>
                    <h2>{name}</h2>
                    <Avatar src={pavan} alt="Pavan Podila" height={256} />
                </div>

                <div dangerouslySetInnerHTML={{ __html: toHTML(bio) }} />

                <List>
                    <SocialIcon url={twitter.url} />
                    <SocialIcon url={github.url} color={palette.dark} />
                    <SocialIcon url={linkedIn} />
                    <SocialIcon url={`mailto:${email}`} />
                </List>

                <h2>A few things I've worked on in the past...</h2>
                <section
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                    }}
                >
                    {highlights.map(x => (
                        <HighlightCard key={x.title}>
                            <a href={x.url}>
                                <div style={{ textAlign: 'center' }}>
                                    <img src={x.icon} alt={x.title} height={200} />
                                </div>
                                <h3>{x.title}</h3>
                                <p>{x.description}</p>
                            </a>
                        </HighlightCard>
                    ))}
                </section>
            </Layout>
        );
    }
}
